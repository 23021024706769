@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #e9edf0;
  --foreground: #000000;
  --font-main: "helveticaNeueLTProMd", "helveticaNeueLTProMd Fallback", sans-serif;
}

html {
  font-family: var(--font-main);
  color: #000000;
  background: var(--background);
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
}

body {
  font-family: var(--font-main);
  color: #000000;
  background: var(--background);
  font-size: 16px;
  line-height: 1.5;
}
