@use "sass:string";

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

.bg-gr-bl {
  background: linear-gradient(90deg, #000000 0%, #0d121c 100%);
}
.bg-gr-gl {
  background: linear-gradient(90deg, #a67c00 0%, #d4af37 100%);
}

.map-city-text {
  fill: #d4af37; /* Light gray to contrast with the dark background */
}

@keyframes wing {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(100px, -50px) rotate(45deg);
  }
  50% {
    transform: translate(200px, 0) rotate(90deg);
  }
  75% {
    transform: translate(100px, 50px) rotate(135deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

.animate-wing {
  animation: wing 2s ease-in-out infinite;
}

.myCropContainer {
  /* 
    This container should fill the parent's height, which is 28vh.
    So we set 'height: 100%' here.
  */
  position: relative;
  width: 100%;
  height: 100%;
  /* 
    If you want the user to be able to drag the crop area, 
    DO NOT set pointer-events: none here.
  */
  background-color: #000; /* optional if you want a dark background behind the image */
}

.myCropArea {
  /* 
    This dims everything except the final rectangle. 
    react-easy-crop positions/scales this element 
    to match the user’s chosen crop region.
  */
  position: absolute;
  inset: 0; /* top:0; left:0; right:0; bottom:0 */
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none; /* user can't interact with the overlay itself */
}

/* The final rectangle. 
   We draw an outline or border so the user sees exactly what portion is saved. 
*/
.myCropArea::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  outline: 1px dotted #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  mix-blend-mode: normal; /* optional */
}

// h1 {
//   font-family: var(--font-tungsten-semibold);
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   line-height: 46px;
// }

.text-main-gold {
  background: linear-gradient(120deg, #c9b037, #d4af37, #ffd700, #d4af37, #c9b037);
  background-size: cover;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-mozg {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  font-family: var(--font-good-times);
  letter-spacing: 6px;
}

.logo-ii {
  font-size: 9.2px;
  font-weight: 400;
  color: #fff;
  font-family: var(--font-good-times);
  letter-spacing: 1px;
}

.text-regular-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #e5e7eb, #c7d2fe, #f9fafb, #a5b4fc, #e5e7eb);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-primary-gradient {
  /* Define a horizontal gradient using your chosen colors */
  background: linear-gradient(90deg, #d4af37, #ffffff, #d4af37, #ffffff);
  background-size: 200% auto; /* Enlarges the gradient for smooth animation */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; /* Ensures text shows the background gradient */
  color: transparent;
  animation: gradientShift 4s ease-in-out infinite; /* Animates the background */
}

// @keyframes gradientShift {
//   0% {
//     background-position: 0% center;
//   }
//   50% {
//     background-position: 100% center;
//   }
//   100% {
//     background-position: 0% center;
//   }
// }

.text-warning-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffcc00 25%, #ffffff 50%, #ffcc00 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-info-gridient {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #b050ff 25%, #ffffff 50%, #b050ff 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-regular-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #e5e7eb, #c7d2fe, #f9fafb, #a5b4fc, #e5e7eb);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-primary-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #8fe548 25%, #ffffff 25%, #8fe548 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-warning-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #ffcc00 25%, #ffffff 25%, #ffcc00 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.text-info-gridient-2 {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #ffffff 25%, #b050ff 25%, #ffffff 25%, #b050ff 75%, #ffffff 100%);
  color: transparent;
  animation: gradient 4s ease-in-out infinite;
}

.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.8) 70%);
  pointer-events: none; /* Ensure overlay doesn't block interaction */
}
.dark-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(3, 7, 18, 0.3) 10%, rgba(3, 7, 18, 0.9) 40%, rgba(3, 7, 18, 0.85) 70%);
  pointer-events: none; /* Ensure overlay doesn't block interaction */
}

.black-overlay-option-1 {
  position: absolute;
  /* Shorthand for top: 0; right: 0; bottom: 0; left: 0; */
  inset: 0;
  z-index: 1;
  pointer-events: none;

  /* Combine a radial gradient with a subtle linear gradient */
  background: 
    /* Radial gradient centered; adjust stops to taste */ radial-gradient(circle at center, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.85) 100%),
    /* Optional linear gradient from top to bottom for extra shading */ linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.75) 100%);
}

.black-overlay-image-1 {
  position: absolute;
  inset: 0;
  z-index: 1;
  pointer-events: none;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.85) 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.75));
}

.black-overlay-image-2 {
  position: absolute;
  inset: 0;
  z-index: 1;
  pointer-events: none;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.6) 90%), linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
}

.black-overlay-option-1-left {
  /* Shorthand for top: 0; right: 0; bottom: 0; left: 0 */
  inset: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none; /* Ensure overlay doesn't block interaction */

  /* Darker on the left, transitioning to lighter on the right */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.1) 100%);
}
.black-overlay-option-1-right {
  /* Cover the entire parent element */
  inset: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none; /* Does not block interaction with underlying elements */

  /* Gradient from right (dark) to left (light) */
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.1) 100%);
}

.dark-overlay-3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(3, 7, 18, 0.7) 10%, rgba(3, 7, 18, 0.95) 40%, rgba(3, 7, 18, 0.95) 70%);
  pointer-events: none; /* Ensure overlay doesn't block interaction */
}

.dark-overlay-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* Linear gradient from left (lighter) to right (darker) */
  background: linear-gradient(to right, rgba(3, 7, 18, 0.2) 0%, rgba(3, 7, 18, 0.6) 50%, rgba(3, 7, 18, 0.9) 100%);
  pointer-events: none; /* Overlay doesn't block clicks */
}
.dark-overlay-2-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* Darker on the left, lighter on the right */
  background: linear-gradient(to right, rgba(3, 7, 18, 0.9) 0%, rgba(3, 7, 18, 0.6) 50%, rgba(3, 7, 18, 0.2) 100%);
  pointer-events: none; /* Ensure overlay doesn't block interaction */
}

.holi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, /* Less bright white */ rgba(139, 69, 19, 0.4) 40%, /* Darker brown */ rgba(0, 0, 0, 0.8) 70%); /* Darker black */
  background-blend-mode: screen;
  mix-blend-mode: lighten;
  pointer-events: none; /* Ensure overlay doesn't block interaction */
  animation: shiftGradient 10s infinite alternate;
}

.holi-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: radial-gradient(circle, rgba(255, 0, 0, 0.5) 10%, rgba(255, 165, 0, 0.5) 30%, rgba(0, 255, 0, 0.5) 50%, rgba(0, 0, 255, 0.5) 70%, rgba(75, 0, 130, 0.5) 90%);
  background-blend-mode: screen;
  mix-blend-mode: multiply;
}

/* Animation for vibrant color pulses (optional) */
@keyframes holiPulse {
  0%,
  100% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }
}

.holi-overlay-2 {
  animation: holiPulse 5s infinite ease-in-out;
}

/* Animation for gradient movement to maintain dynamism */
@keyframes shiftGradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.nebula-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 50%, rgba(58, 125, 170, 0.3), transparent), radial-gradient(circle at 70% 20%, rgba(255, 99, 71, 0.2), transparent),
    radial-gradient(circle at 90% 70%, rgba(128, 0, 128, 0.3), transparent);
  opacity: 0.5;
  animation: nebulaMove 30s infinite ease-in-out;
  z-index: 1;
}

/* Nebula movement animation */
@keyframes nebulaMove {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 100px;
  }
}

.pulse-marker {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    r: 6;
    opacity: 1;
  }
  50% {
    r: 8;
    opacity: 0.7;
  }
  100% {
    r: 6;
    opacity: 1;
  }
}

@keyframes fadeInPulse {
  0% {
    opacity: 0;
    transform: scale(0.9);
    fill: #8fe548; /* Starting color */
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    fill: #facc15; /* Mid color (yellow) */
  }
  100% {
    opacity: 1;
    transform: scale(1);
    fill: #38bdf8; /* Ending color (light blue) */
  }
}

.bg-card-dark-2 {
  background-color: rgb(31 41 55 / 0.4);
  border: 1px solid #1f2937;
  border-radius: 1rem;
}

$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"), ("(", "%28"), (")", "%29")) !default;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/* Define theme colors */
$theme-colors: (
  "primary": "#8fe548",
  "regular": "#a5b4fc",
  "info": "#b050ff",
  "warning": "#ffcc00",
  "danger": "#dc3545",
  "black": "#000000",
  "gold": "#D4AF37"
) !default;

/* Generate dynamic color classes */
@each $color, $value in $theme-colors {
  ul.custom-check-list-#{$color} {
    list-style-type: none; /* Remove default bullet */
    padding-left: 0; /* Remove padding */
  }

  ul.custom-check-list-#{$color} li {
    position: relative; /* Enable positioning for the pseudo-element */
    padding-left: 24px; /* Space for the icon */
  }

  ul.custom-check-list-#{$color} li::before {
    content: "";
    position: absolute;
    left: 0;
    // top: 0.1em;
    width: 18px;
    height: 18px;
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M13.3035 4.76425C13.5718 4.44865 14.0451 4.41027 14.3607 4.67853C14.6763 4.9468 14.7147 5.42012 14.4464 5.73572L8.07144 13.2357C7.79896 13.5563 7.31616 13.5901 7.00171 13.3105L3.62671 10.3105C3.31713 10.0354 3.28924 9.5613 3.56443 9.25172C3.83962 8.94213 4.31367 8.91424 4.62326 9.18943L7.42515 11.68L13.3035 4.76425Z' fill='#{$value}'/></svg>")
    );
    background-size: contain;
    background-repeat: no-repeat;
  }

  ul.custom-check-list-soft-bg-#{$color} li::before {
    background-image: escape-svg(
      url("data:image/svg+xml,<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='18' height='18' rx='9' fill='#{$value}' fill-opacity='0.1'/><path d='M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z' fill='#{$value}'/></svg>")
    );
  }
}

@each $size, $dimensions in (sm: 14px, md: 18px, lg: 22px, xl: 26px) {
  ul.custom-check-list-size-#{$size} li::before {
    width: $dimensions;
    height: $dimensions;
  }

  ul.custom-check-list-size-#{$size} li {
    padding-left: $dimensions + 16px;
  }
}

@keyframes flickerAnimation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.9;
  }
}

.flicker {
  animation: flickerAnimation 2s ease-out 1 forwards;
}

@keyframes colorStain {
  0% {
    color: white; /* Start color */
  }
  25% {
    color: #8fe548; /* Red */
  }
  50% {
    color: white; /* Yellow */
  }
  75% {
    color: #8fe548; /* Green */
  }

  100% {
    color: white; /* Back to Start */
  }
}

.logo-stain {
  animation: colorStain 5s infinite alternate;
}

// .image-gallery-slide img {
//   max-height: 80vh; /* or whatever max you want */
//   width: auto;
//   height: auto;
//   margin: 0 auto; /* center horizontally if needed */
//   object-fit: contain;
// }

/* 2) Highlight the active thumbnail with the same primary color */
.image-gallery-thumbnail {
  /* Container is bigger to accommodate border */
  width: 84px !important;
  height: 84px !important;
  box-sizing: border-box;
  overflow: visible;
}

.image-gallery-thumbnail img {
  /* The image remains 80x80, so there's a 2px buffer for the border */
  width: 80px;
  height: 80px;
  object-fit: cover;
}

/* Keep your active/focus styles */
.image-gallery .image-gallery-thumbnail.active,
.image-gallery .image-gallery-thumbnail:focus {
  border: 2px solid #ffd700 !important;
  outline: none;
}

/* 3) Reduce the visibility (opacity) of the left/right arrows */
.image-gallery .image-gallery-left-nav,
.image-gallery .image-gallery-right-nav {
  opacity: 0.55; /* less visible */
  transition: opacity 0.3s ease;
  color: #ffd700;
}

/* 4) On hover, make them slightly more visible */
.image-gallery .image-gallery-left-nav:hover,
.image-gallery .image-gallery-right-nav:hover {
  opacity: 0.4;
}

/* 5) Adjust bullet color if using bullets at some point (optional) */
.image-gallery-bullets .image-gallery-bullet {
  border: 2px solid #ffd700;
  background: transparent;
}
.image-gallery-bullets .image-gallery-bullet.active,
.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet:focus {
  background: #ffd700;
  border-color: #ffd700;
}
